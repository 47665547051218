import axiosRequest from '@/functions/axiosRequests.js';
import pathResolver from '@/functions/pathResolver.js';

let app = pathResolver.primaryPaths('app');
let tenant = pathResolver.primaryPaths('tenant');

export default {
  namespaced: true,

  state: {
    data: {
      collectionsParent: {},
      collectionsLines: [],
      groupCollections: [],
    },
    invalidParents: [],
    invalidLines: [],
    collectionView: false,
    boardContent: null,
    collectionList: [],
  },

  mutations: {
    //
  },

  actions: {
    async getParentColumns ({}) {
      return await axiosRequest
        .get(`/${app}/${tenant}/states/sales_collections`, { except: ['id', 'created_at', 'updated_at', 'deleted_at'] });
    },

    async getLinesColumns ({}) {
      return await axiosRequest
        .get(`/${app}/${tenant}/states/sales_collection_lines`, { except: ['created_at', 'updated_at', 'deleted_at'] });
    },

    async setLineColumns ({ dispatch, state, rootState }) {
      const existingLines = state.data.collectionsLines
      const columns = await dispatch('getLinesColumns')
      columns.type = 1
      state.data.collectionsLines.push(columns)
    },

    removeCollectionLine({ state }, index) {
      state.data.collectionsLines.splice(index, 1);
    },

    async setGroupLine({ dispatch, state }) {
      const parent = await dispatch('getParentColumns')
      const lines = await dispatch('getLinesColumns')
      
      lines.amount = '0';
      lines.base_tax_amount = '0';
      lines.deferred_tax_amount = '0';
      
      let newLines = { money: lines, prepaid: lines, vat: lines, realized: lines }

      state.data.groupCollections.push(
        { 
          collectionsParent: parent,
          collectionsLines: newLines,
        },
      )
    },

    removeGroupLine({ state }, index) {
      state.data.groupCollections.splice(index, 1);
    },

    setCollectionList({ commit, dispatch, state }, collections) {
      state.collectionList = collections
    },
    
    async getCollectionById({ dispatch, commit, state }, id) {
      return await axiosRequest
        .get(`/${app}/${tenant}/sales/credit-sales/sales-collections/${id}`)
        .then(async (response) => {
          state.data.collectionsParent.collection_date = response.collection_date
          state.data.collectionsParent.receipt_type = response.receipt_type
          state.data.collectionsParent.receipt_number_prefix = response.receipt_number_prefix
          state.data.collectionsParent.receipt_number_series = response.receipt_number_series
          
          await dispatch('setLineColumns');

          const lines = response.lines.map((line, index) => {
            line.type = parseInt(line.type)
            return line;
          });
          
          state.data.collectionsLines = response.lines
        });
    },

    async getSaleById({ dispatch, commit, state }, id) {
      return await axiosRequest.get(`/${app}/${tenant}/sales/credit-sales/${id}?saleOnly=true`)
    },

    async update({}, params) {
      return await axiosRequest
        .put(`/${app}/${tenant}/sales/credit-sales/sales-collections/${params.id}`, params)
    },

    async delete({}, params) {
      return await axiosRequest
        .delete(`/${app}/${tenant}/sales/credit-sales/sales-collections/${params.id}`, params)
    },

    async submit({ state, rootState }, sale) {
      return await axiosRequest
        .post(`/${app}/${tenant}/sales/credit-sales/sales-collections/store/${sale}`, {
          parent: state.data.collectionsParent,
          lines: state.data.collectionsLines
        })
        .then((response) => {
          const myTimeout = setTimeout(function () {
            rootState.alerts.saving = false
          }, 1000);
          
          if (response.message === 'invalid') {
            // state.invalidParents = response.data.parent_error_keys

            const lineWithValidations = state.data.collectionsLines.map((line, index) => {
              delete line['invalid'];

              if (response.lines_errors[index] !== undefined) {
                line.invalid = response.lines_errors[index]
              }

              return line
            })
            
            state.data.collectionsLines = lineWithValidations;
            
            setTimeout(function () {
              rootState.alerts.invalid = true
            }, 1000);

            return response.message
          } else {
            rootState.alerts.submitting = false;
            rootState.alerts.submitted = true;

            return response;
          }
        })
        .catch(function (error) {
          console.log('has errors', error);
        });
    },

    async submitGroup({ state, rootState }, param) {
      return await axiosRequest
        .post(`/${app}/${tenant}/sales/credit-sales/group-collections/store?type=${param.type}`, state.data.groupCollections)
        .then((response) => {
          if (response.message === 'invalid') {

            /**
             * @info Get the first error object (money) 
             * since changes should be made on the first row
             * */
            let lineErrors = response.lines_errors;
            let parentErrors = response.parent_error_keys;

            state.invalidParents = parentErrors;
            state.invalidLines = lineErrors;
            
            setTimeout(function () {
              rootState.alerts.invalid = true
            }, 1000);

            return response.message
          } else {
            rootState.alerts.submitting = false;
            rootState.alerts.submitted = true;

            return response;
          }
        })
        .catch(function (error) {
          console.log('has errors', error);
        });
    },
  }
}