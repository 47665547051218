import pathResolver from '@/functions/pathResolver.js';
import { processors } from '../../../functions/processors';

let app = pathResolver.primaryPaths('app');
let tenant = pathResolver.primaryPaths('tenant');

export default {
  namespaced: true,

  state: {
    tab: '',
    page: {
      list: 'invoice',
      form: false,
      autosave: false,
    },
    data: {
      parent: {},
      lines: [],
    },
    parentValidations: [],
    lineValidations: [],
  },

  mutations: {
    setLine(state, { line }) {
      state.data.lines.push(line);
    },

    removeLine(state, { index }) {
      state.data.lines.splice(index, 1);
    }
  },

  actions: {
    async getParentColumns ({ commit, dispatch, state }, url) {
      return await new Promise((resolve, reject) => {
        axios.get(url, { params: { except: ['id', 'created_at', 'updated_at', 'deleted_at'] } })
          .then(async (response) => {
            resolve(response.data)
          })
          .catch(function (error) {
            console.log(error);
            reject();
          });
      });
    },

    async getLinesColumns ({ commit, dispatch, state }, url) {
      return await new Promise((resolve, reject) => {
        axios.get(url, { params: { except: ['id', 'created_at', 'updated_at', 'deleted_at'] } })
          .then(async (response) => {
            resolve(response.data)
          })
          .catch(function (error) {
            console.log(error);
            reject();
          });
      });
    },
    
    setLineColumns ({ dispatch, rootState }, url) {
      return new Promise((resolve, reject) => {
        axios.post(url)
          .then(async (response) => {
            dispatch('setDataToState', response.data.sale)
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject();
          });
      });
    },

    setLineColumnsOnly({ commit }, line) {
      commit('setLine', { line });
    },

    removeLineColumnsOnly({ commit }, index) {
      commit('removeLine', { index });
    },

    deleteLineColumns ({ dispatch, rootState }, url) {
      return new Promise((resolve, reject) => {
        axios.delete(url)
          .then(async (response) => {
            dispatch('setDataToState', response.data.sale)
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject();
          });
      });
    },

    setDataToState({ commit, dispatch, state }, data) {
      const parent = Object.keys(data)
        .filter(key => key !== 'lines')
        .reduce((acc, key) => {
          acc[key] = data[key];
          return acc;
        }, {});

      state.data.parent = parent

      const lines = data.lines
      if (lines.length > 0) {
        state.data.lines = lines
      }

      state.page.list = parent.transaction_type
      state.page.form = true

      dispatch('setGrandTotals')
    },
    
    createDraft ({ commit, dispatch, state, rootState }) {
      state.data.parent.transaction_type = state.page.list
      state.data.parent.is_tax_exclusive = true
      state.data.parent.status = 'Draft'
      
      axios.interceptors.request.use((config) => {
        Swal.fire({
          didOpen: () => {
            Swal.showLoading();
          },
          html: '<small>Creating Draft...</small>',
          allowOutsideClick: false,
          showCloseButton: true,
        })

        return config
      })

      // params.parent = state.data.parent
      // params.lines = state.data.lines

      dispatch('store').then((res) => {
        rootState.alerts.createDraft = false;
        window.location.href = `/${app}/${tenant}/sales/credit-sales/${res.id}`
      })
    },

    async createAnotherDraft ({ commit, dispatch, state, rootState }) {
      axios.interceptors.request.use((config) => {
        rootState.alerts.createDraft = true;
        return config;
      })

      let newParent = await dispatch('getParentColumns', `/${app}/${tenant}/states/sales`);
      let newLine = await dispatch('getLinesColumns', `/${app}/${tenant}/states/sales_lines`);

      state.data.parent = {};
      state.data.parent = newParent
      state.data.parent.transaction_type = state.page.list
      state.data.parent.is_tax_exclusive = true
      state.data.parent.status = 'Draft'
      
      state.data.lines = [];
      state.data.lines = [newLine];
      
      dispatch('store').then((res) => {
        window.location.href = `/${app}/${tenant}/sales/credit-sales/${res.id}`
      });
    },

    autoSaveInputs({ commit, dispatch, state, rootState }, params) {
      axios.interceptors.request.use((config) => {
        rootState.alerts.saving = true
        return config
      })
      
      dispatch('update', params)
    },

    store({ commit, dispatch, state, rootState }) {
      return axios
        .post(`/${app}/${tenant}/sales/credit-sales/store`, {
          parent: state.data.parent,
          lines: state.data.lines,
        })
        .then((response) => {
          return response.data.sale
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    update({ state, rootState }, params) {
      console.log('params', params)
      return axios.put(`/${app}/${tenant}/sales/credit-sales/${state.data.parent.id}/update?action=true&status=${params.status}`, {
          parent: state.data.parent,
          lines: state.data.lines,
        })
        .then((response) => {
          const myTimeout = setTimeout(function () {
            rootState.alerts.saving = false
          }, 500);
          
          if (response.data.message === 'invalid') {
            /**
             * @info Set validation boolean individual fields
             * */
            const parentErrors = response.data.parentErrors;
            let validatedParents = processors.validate(parentErrors);
            state.parentValidations = validatedParents.keys;
            
            /**
             * @info Set validation boolean for dynamic row fields
             * */
            const lineErrors = response.data.lineErrors;
            let validatedRows = processors.validateRows(lineErrors);
            state.lineValidations = validatedRows;

            setTimeout(function () {
              rootState.alerts.invalid = true
            }, 500);

            rootState.alerts.saving = false
            return response.data.message
          }

          rootState.alerts.saving = false
          return response.data.parent
        })
        .catch(function (error) {
          console.log('has errors', error);
        });
    },

    setSubTotals({ commit, dispatch, state }) {
      let lines = state.data.lines;
      lines.map((line, index) => {
        const quantity = lines[index].quantity
        const unitPrice = lines[index].unit_price
        const subTotal = unitPrice * quantity

        state.data.lines[index]['sub_total'] = parseFloat(subTotal).toFixed(2)
      })
    },

    setTaxAmounts({ commit, dispatch, state, rootState }) {
      const rateList = rootState.taxRates.rates
      
      let lines = state.data.lines;
      lines.map((line, index) => {
        const quantity = lines[index].quantity
        const unitPrice = lines[index].unit_price
        const subTotal = unitPrice * quantity
        const taxRate = lines[index].tax_rate_id
        
        if (taxRate) {
          const percentage = _.find(Object.values(rateList), { id: parseInt(taxRate) }).percentage / 100;
          const taxAmount = subTotal * percentage

          state.data.lines[index]['tax_amount'] = parseFloat(taxAmount).toFixed(2)
        }
      })
    },

    setTotals({ commit, dispatch, state }) {
      let lines = state.data.lines;
      
      lines.map((line, index) => {
        console.log()
        const quantity = lines[index].quantity
        const unitPrice = lines[index].unit_price
        const subTotal = unitPrice * quantity
        const taxRate = lines[index].tax_rate_id
        const taxAmount = lines[index].tax_amount

        const totalAmount = parseFloat(subTotal) + parseFloat(taxAmount || 0);

        state.data.lines[index]['total_amount'] = parseFloat(totalAmount).toFixed(2)
      })
    },

    setGrandTotals({ commit, dispatch, state, rootState }) {
      const rateList = rootState.taxRates.rates
      let lines = state.data.lines;
      
      let all_sub_total = [];
      let all_vatable = [];
      let all_vat_exempt = [];
      let all_zero_rated = [];
      let all_no_tax = [];
      let all_vat = [];

      lines.map((line, index) => {
        const quantity = lines[index].quantity
        const unitPrice = lines[index].unit_price
        const subTotal = unitPrice * quantity
        const taxRate = lines[index].tax_rate_id
        
        if (taxRate) {
          // Get all row subtotals
          all_sub_total.push(subTotal);

          // Get vatables
          const vatable = _.find(rateList, { id: parseInt(taxRate), code: 'vatable' });
          if (vatable) {
            all_vatable.push(subTotal);
          }

          // Get vat exempt
          const vatExempt = _.find(rateList, { id: parseInt(taxRate), code: 'vat_exempt' });
          if (vatExempt) {
            all_vat_exempt.push(subTotal);
          }

          // Get zero rated
          const zeroRated = _.find(rateList, { id: parseInt(taxRate), code: 'zero_rated' });
          if (zeroRated) {
            all_zero_rated.push(subTotal);
          }

          // Get no tax
          const noTax = _.find(rateList, { id: parseInt(taxRate), code: 'no_tax' });
          if (noTax) {
            all_no_tax.push(subTotal);
          }
          
          // Get vat
          const taxSelected = _.find(rateList, { id: parseInt(taxRate) });
          if (taxSelected) {
            const taxAmount = subTotal * (taxSelected.percentage / 100)
            all_vat.push(taxAmount);
          }
        }
      })

      const totalSubTotals = all_sub_total.reduce((sum, value) => sum + value, 0);
      const totalVatables = all_vatable.reduce((sum, value) => sum + value, 0);
      const totalVatExempt = all_vat_exempt.reduce((sum, value) => sum + value, 0);
      const totalZeroRated = all_zero_rated.reduce((sum, value) => sum + value, 0);
      const totalNoTax = all_no_tax.reduce((sum, value) => sum + value, 0);
      const totalVat = all_vat.reduce((sum, value) => sum + value, 0);
      const totalGross = totalVatables + totalVatExempt + totalZeroRated + totalNoTax + totalVat

      state.data.parent.sub_total = parseFloat(totalSubTotals).toFixed(2)
      state.data.parent.total_vatable = parseFloat(totalVatables).toFixed(2)
      state.data.parent.total_vat_exempt = parseFloat(totalVatExempt).toFixed(2)
      state.data.parent.total_zero_rated = parseFloat(totalZeroRated).toFixed(2)
      state.data.parent.total_no_tax = parseFloat(totalNoTax).toFixed(2)
      state.data.parent.total_tax = parseFloat(totalVat).toFixed(2)
      state.data.parent.grand_total = parseFloat(totalGross).toFixed(2)
    }
  },
};