import axiosRequest from '@/functions/axiosRequests.js';
import pathResolver from '@/functions/pathResolver.js';
import { processors } from '../../../functions/processors';

let app = pathResolver.primaryPaths('app');
let tenant = pathResolver.primaryPaths('tenant');
let module = pathResolver.primaryPaths('module');

export default {
  namespaced: true,

  state: {
    data: {},
    validations: [],
  },

  actions: {
    async getColumns({ commit, dispatch, state }, url) {
      return await axiosRequest.get(url, {
        except: ['id', 'created_at', 'updated_at', 'deleted_at']
      });
    },

    async setColumns({ dispatch, state }) {
      const columns = await dispatch(
        'getColumns',
        `/${app}/${tenant}/states/payments`
      );

      state.data = columns;
    },

    async getPayments({ commit, dispatch, state }, param) {
      return await axiosRequest.get(`/${app}/${tenant}/purchases/bills-to-pay/${param.id}/payments?json=true`);
    },

    store({ commit, dispatch, state, rootState }, param) {
      return axios
        .post(`/${app}/${tenant}/purchases/bills-to-pay/${param.id}/payments`, {
          data: state.data,
        }).then((response) => {
          setTimeout(function () {
            rootState.alerts.saving = false
          }, 500);

          if (response.data.message === 'invalid') {
            /**
             * @info Set validation boolean individual fields
             * */
            const errors = response.data.errors;
            let validated = processors.validate(errors);
            state.validations = validated.keys;

            setTimeout(function () {
              rootState.alerts.invalid = true
            }, 500);

            rootState.alerts.saving = false
            return response.data.message
          }

          return response.data
        }).catch(function (error) {
          console.log(error);
        });
    },
  }
};
