import pathResolver from "@/functions/pathResolver.js";
import axiosRequest from '@/functions/axiosRequests.js';
import { processors } from "@/functions/processors";

let app = pathResolver.primaryPaths("app");
let tenant = pathResolver.primaryPaths("tenant");

export default {
  namespaced: true,

  state: {
    data: [],
    fieldValidations: [],
    fieldValidationMessages: [],
    bankTransfer: {
      transfer_date: '',
      account_from: '',
      account_to: '',
      amount: '',
      description: '',
      reference: '',
    },
    transactionParentValidations: [],
    transactionLineValidations: [],
  },

  mutations: {
    //
  },

  actions: {
    async getAccountColumns({ commit, dispatch, state }) {
      return await axiosRequest.get(`/${app}/${tenant}/states/bank_accounts`, { 
        except: ['id', 'created_at', 'updated_at', 'deleted_at']
      });
    },

    async getTransactionColumns({ commit, dispatch, state }) {
      return await axiosRequest.get(`/${app}/${tenant}/states/bank_transactions`, { 
        except: ['id', 'created_at', 'updated_at', 'deleted_at']
      });
    },

    async getTransactionLineColumns({ commit, dispatch, state }) {
      return await axiosRequest.get(`/${app}/${tenant}/states/bank_transaction_lines`, { 
        except: ['id', 'created_at', 'updated_at', 'deleted_at']
      });
    },

    async setAccountDataToState({ dispatch, state }, data) {
      const columns = await dispatch('getAccountColumns');
      
      if (data) {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            columns[key] = data[key];
          }
        }
      }
      
      columns.currency = 'PHP';
      state.data = columns;
    },

    // async setTransactionDataToState({ dispatch, state }, data) {
    //   const columns = await dispatch('getTransactionColumns');
      
    //   if (data) {
    //     for (const key in data) {
    //       if (data.hasOwnProperty(key)) {
    //         columns[key] = data[key];
    //       }
    //     }
    //   }
      
    //   columns.currency = 'PHP';
    //   state.data = columns;
    // },

    async setTransactionState({ dispatch, state }) {
      const parentColumns = await dispatch('getTransactionColumns');
      const lineColumns = await dispatch('getTransactionLineColumns');

      state.data = {
        transaction: parentColumns,
        transaction_lines: [lineColumns]
      };
    },

    storeAccount({ dispatch, state, rootState }) {
      rootState.alerts.submitting = true;
      const data = state.data;
      
      axiosRequest.post(`/${app}/${tenant}/banking`, data)
        .then((response) => {
          if (response.status === 'invalid') {
            const validatedFields = processors.validate(response.errors);
            state.fieldValidations = validatedFields.keys;

            setTimeout(function () {
              rootState.alerts.invalid = true
            }, 500);

            rootState.alerts.saving = false

            setTimeout(function () {
              rootState.alerts.invalid = false
            }, 5000);
          }

          if (response.status === 'chart-account-invalid') {
            setTimeout(function () {
              rootState.alerts.customMessageError = 
                [true, 'Cannot save bank account to Chart of Accounts. Please try again or inform Administrator']
            }, 500); 
          }

          if (response.status === 'success') {
            setTimeout(function () {
              rootState.alerts.submitting = false
            }, 500);

            window.location.href = `/${app}/${tenant}/banking`
          }
        });
    },

    updateAccount({ dispatch, state, rootState }) {
      rootState.alerts.submitting = true;

      const data = state.data;
      axiosRequest.put(`/${app}/${tenant}/banking/${data.id}`, data)
        .then((response) => {
          if (response.status === 'invalid') {
            const validatedFields = processors.validate(response.errors);
            state.fieldValidations = validatedFields.keys;

            setTimeout(function () {
              rootState.alerts.invalid = true
            }, 500);

            rootState.alerts.saving = false

            setTimeout(function () {
              rootState.alerts.invalid = false
            }, 5000);
          }

          if (response.status === 'chart-account-invalid') {
            setTimeout(function () {
              rootState.alerts.customMessageError = 
                [true, 'Cannot save bank account to Chart of Accounts. Please try again or inform Administrator']
            }, 500); 
          }

          if (response.status === 'success') {
            setTimeout(function () {
              rootState.alerts.submitting = false
            }, 500);

            window.location.href = `/${app}/${tenant}/banking`
          }
        });
    },

    storeTransfer({ state, rootState }) {
      rootState.alerts.submitting = true;

      const data = state.bankTransfer;
      axiosRequest.post(`/${app}/${tenant}/banking/transfer`, data)
        .then(response => {
          if (response.status === 'invalid') {
            const validatedFields = processors.validate(response.errors);
            state.fieldValidations = validatedFields.keys;
            state.fieldValidationMessages = validatedFields.messages;

            setTimeout(function () {
              rootState.alerts.invalid = true
            }, 500);

            rootState.alerts.saving = false
          }

          if (response.status === 'success') {
            setTimeout(function () {
              rootState.alerts.submitting = false
            }, 500);

            window.location.href = `/${app}/${tenant}/banking`
          }
        });
    },

    storeSpendReceive({ state, rootState }, params) {
      axiosRequest.post(`/${app}/${tenant}/banking/${params.type}/${params.bankAccount}`, {
        'parent': state.data.transaction,
        'lines': state.data.transaction_lines,
      }).then(response => {
        const myTimeout = setTimeout(function () {
          rootState.alerts.saving = false
        }, 500);
        
        if (response.message === 'invalid') {
          const parentErrors = response.parentErrors;
          let validatedParents = processors.validate(parentErrors);
          state.transactionParentValidations = validatedParents.keys;

          const lineErrors = response.lineErrors;
          let validatedRows = processors.validateRows(lineErrors);
          state.transactionLineValidations = validatedRows;

          setTimeout(function () {
            rootState.alerts.invalid = true
          }, 500);
          
          rootState.alerts.saving = false
          return response.message
        }

        rootState.alerts.saving = false
        return response.parent
      });
    },

    async getBankAccounts({}, params) {
      return await axiosRequest.get(`/${app}/${tenant}/resources/bank_accounts`)
        .then(response => {
          return response;
        });
    },

    setSubTotals({ commit, dispatch, state }) {
      let lines = state.data.transaction_lines;
      lines.map((line, index) => {
        const quantity = lines[index].quantity
        const unitPrice = lines[index].unit_price
        const subTotal = unitPrice * quantity

        state.data.transaction_lines[index]['sub_total'] = parseFloat(subTotal).toFixed(2)
      })
    },

    setTaxAmounts({ commit, dispatch, state, rootState }) {
      const rateList = rootState.taxRates.rates
      
      let lines = state.data.transaction_lines;
      lines.map((line, index) => {
        const quantity = lines[index].quantity
        const unitPrice = lines[index].unit_price
        const subTotal = unitPrice * quantity
        const taxRate = lines[index].tax_rate_id
        
        if (taxRate) {
          const percentage = _.find(Object.values(rateList), { id: parseInt(taxRate) }).percentage / 100;
          const taxAmount = subTotal * percentage

          state.data.transaction_lines[index]['tax_amount'] = parseFloat(taxAmount).toFixed(2)
        }
      })
    },

    setTotals({ commit, dispatch, state }) {
      let lines = state.data.transaction_lines;
      
      lines.map((line, index) => {
        console.log()
        const quantity = lines[index].quantity
        const unitPrice = lines[index].unit_price
        const subTotal = unitPrice * quantity
        const taxRate = lines[index].tax_rate_id
        const taxAmount = lines[index].tax_amount

        const totalAmount = parseFloat(subTotal) + parseFloat(taxAmount || 0);

        state.data.transaction_lines[index]['total_amount'] = parseFloat(totalAmount).toFixed(2)
      })
    },

    setGrandTotals({ commit, dispatch, state, rootState }) {
      const rateList = rootState.taxRates.rateSelectAllData
      let lines = state.data.transaction_lines;
      
      let all_vatable = [];
      let all_vat_exempt = [];
      let all_zero_rated = [];
      let all_no_tax = [];
      let all_vat = [];

      lines.map((line, index) => {
        const quantity = lines[index].quantity
        const unitPrice = lines[index].unit_price
        const subTotal = unitPrice * quantity
        const taxRate = lines[index].tax_rate_id
        
        if (taxRate) {
          // Get vatables
          const vatable = _.find(rateList, { id: parseInt(taxRate), code: 'vatable' });
          if (vatable) {
            all_vatable.push(subTotal);
          }

          // Get vat exempt
          const vatExempt = _.find(rateList, { id: parseInt(taxRate), code: 'vat_exempt' });
          if (vatExempt) {
            all_vat_exempt.push(subTotal);
          }

          // Get zero rated
          const zeroRated = _.find(rateList, { id: parseInt(taxRate), code: 'zero_rated' });
          if (zeroRated) {
            all_zero_rated.push(subTotal);
          }

          // Get no tax
          const noTax = _.find(rateList, { id: parseInt(taxRate), code: 'no_tax' });
          if (noTax) {
            all_no_tax.push(subTotal);
          }
          
          // Get vat
          const taxSelected = _.find(rateList, { id: parseInt(taxRate) });
          if (taxSelected) {
            const taxAmount = subTotal * (taxSelected.percentage / 100)
            all_vat.push(taxAmount);
          }
        }
      })

      const totalVatables = all_vatable.reduce((sum, value) => sum + value, 0);
      const totalVatExempt = all_vat_exempt.reduce((sum, value) => sum + value, 0);
      const totalZeroRated = all_zero_rated.reduce((sum, value) => sum + value, 0);
      const totalNoTax = all_no_tax.reduce((sum, value) => sum + value, 0);
      const totalVat = all_vat.reduce((sum, value) => sum + value, 0);
      const totalGross = totalVatables + totalVatExempt + totalZeroRated + totalNoTax + totalVat

      state.data.transaction.total_vatable = parseFloat(totalVatables).toFixed(2)
      state.data.transaction.total_vat_exempt = parseFloat(totalVatExempt).toFixed(2)
      state.data.transaction.total_zero_rated = parseFloat(totalZeroRated).toFixed(2)
      state.data.transaction.total_no_tax = parseFloat(totalNoTax).toFixed(2)
      state.data.transaction.total_tax = parseFloat(totalVat).toFixed(2)
      state.data.transaction.grand_total = parseFloat(totalGross).toFixed(2)
    }
  },
};
