export default {
  COLLECTION_TYPE: {
    MONEY_COLLECTION: 1,
    PREPAID_TAX: 2,
    VAT_WITHHELD: 3,
    REALIZED_VAT: 4,
  },

  COLLECTION_TYPE_STRING: {
    1: 'Money Collection',
    2: 'Prepaid Tax',
    3: 'VAT Withheld',
    4: 'Realized VAT',
  },

  COLLECTION_RECEIPT_TYPE: {
    CRN: 1,
    ARN: 2,
    ORN: 3,
  },
  
  COLLECTION_RECEIPT_TYPE_STRING: {
    1: 'Collection Receipt Number',
    2: 'Acknowledgement Receipt Number',
    3: 'Official Receipt Number',
  },

  CASH_BANK_ACCOUNT_TYPES: {
    CREDIT: 1,
    CHECKING: 2,
    OTHERS: 3,
  },

  CASH_ACCOUNT_TYPES: {
    PETTY: 1,
    COUNTER: 2,
  },

  CASH_ACCOUNT_TYPES_STRING: {
    1: 'Petty Cash',
    2: 'Cash Counter',
  },
}