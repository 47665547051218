export default {
  async get(url, params) {
    return await new Promise((resolve, reject) => {
      axios.get(url, { params: params })
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(function (error) {
          console.log(error);
          reject();
        });
    });
  },

  async post(url, params) {
    return await new Promise((resolve, reject) => {
      const config = params ? Object.assign({}, params) : {};
      axios.post(url, config)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(function (error) {
          console.log(error);
          reject();
        });
    });
  },

  async put(url, params) {
    return await new Promise((resolve, reject) => {
      const config = params ? Object.assign({}, params) : {};
      axios.put(url, config)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(function (error) {
          console.log(error);
          reject();
        });
    });
  },

  async delete(url, params) {
    return await new Promise((resolve, reject) => {
      const config = params ? Object.assign({}, params) : {};
      axios.delete(url, config)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(function (error) {
          console.log(error);
          reject();
        });
    });
  }
}
