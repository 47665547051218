
export function dataTables() {
  function loadDataTable(tableId = null, url, columns, columnDefs, selectable, search, orderColumn) {
    $(`#${tableId}`).DataTable({
      dom: "<'row'<'col-sm-12 col-md-6'B><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-3'i><'col-sm-12 col-md-3'l><'col-sm-12 col-md-6'p>>",
      processing: true,
      serverSide: true,
      lengthChange: false,
      // buttons: ["copy", "excel", "pdf", "colvis"],
      responsive: true,
      // scrollX: true,
      ajax: {
        url: url,
        dataType: "json",
        method: "GET",
        data: function(data) {
          data.tableId = tableId
        },
        error: function(xhr, error, code) {
          console.log('error?', xhr, error, code)
          Swal.fire({
            icon: 'info',
            title: "Data Processing",
            text: 'Please click to refresh the page.',
            confirmButtonText: `Refresh`,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          })
        },
      },
      columns: columns,
      columnDefs: columnDefs,
      'select': selectable,
      'order': orderColumn,
      "searching": search,
      lengthChange: true,
      "pagingType": "full_numbers",
      "pageLength": 10,
      "lengthMenu": [
        [10, 25, 50, 100, 200],
        [10, 25, 50, 100, 200]
      ],
      "language": {
        "lengthMenu": "Showing &nbsp; _MENU_ &nbsp; items per page",
      },
      initComplete: function(settings, json) {
        $(`#${tableId}_wrapper .dt-buttons`).css('display', 'none')
        //reloadDataTable(tableId, url, columns, columnDefs, selectable, search, orderColumn);
      },
      drawCallback: function(settings) {
        let currentPage = parseInt($(`#${tableId}_paginate .paginate_button.current`).text())
        let totalPage = $(`#${tableId}_paginate a:not(.first, .previous, .next, .last)`).length
        let totalItems = settings.json.recordsTotal

        let pageRange = Array.from({
          length: totalPage
        }, (x, i) => i + 1);
        
        let pageOpts = '';
        pageRange.forEach(el => {
          if (el === currentPage) {
            pageOpts += '<option selected value="' + el + '">' + el + '</option>';
          } else {
            pageOpts += '<option value="' + el + '">' + el + '</option>';
          }
        });
  
        let selectField = '<select class="form-control form-control-sm custom_paginate_select" style="width:50px;">' + pageOpts + '</select>'
  
        $('.dataTables_info').html(
          'Page &nbsp;' + selectField + ' &nbsp;of ' + totalPage + ' (' + totalItems + ' total items)'
        ).addClass('d-flex justify-content-start align-items-center p-0');
  
        $(document).on('change', '.custom_paginate_select', function() {
          var pageNum = $(this).val() - 1;
          var oTable = $(`#${tableId}`).dataTable();
          oTable.fnPageChange(pageNum);
        })
      }
    })
  }
  
  function reloadDataTable(newTableId, url, columns, columnDefs, selectable, search, orderColumn) {
    // $(`#${newTableId}`).DataTable().ajax.reload()
    $(`#${newTableId}`).DataTable().destroy()
    loadDataTable(newTableId, url, columns, columnDefs, selectable, search, orderColumn)
  }

  return {
    loadDataTable,
    reloadDataTable
  };
}