export const processors = {
  translate(path) {
    const paths = path.split('.');
    
    let value = this.$page.props.translations;

    for (const p of paths) {
      if (value.hasOwnProperty(p)) {
        value = value[p];
      } else {
        return path;
      }
    }

    return value;
  },

  validate(errors) {
    const errorKeys = Object.keys(errors);
    
    const keys = errorKeys.reduce((errorObj, errorKey) => {
      errorObj[errorKey] = true;
      return errorObj;
    }, {});
    
    const messages = {};

    Object.entries(errors).forEach(([key, [value]]) => {
      messages[key] = value;
    });
    
    return {
      keys: keys,
      messages: messages
    };
  },

  validateRows(rowsKeys) {
    const rowKeysWithErrors = rowsKeys.map((errors) => {
      const errorKeys = Object.keys(errors);

      return errorKeys.reduce((errorObj, errorKey) => {
        errorObj[errorKey] = true;
        return errorObj;
      }, {})
    });
    
    return rowKeysWithErrors;
  },
};