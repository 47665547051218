export const formatter = {
  currency(value) {
    const formattedValue = Number(value || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  
    return formattedValue;
  },

  displayDate(dateString) {
    return moment(dateString).format('DD MMM YY');
  }
};