import axiosRequest from '@/functions/axiosRequests.js';

export default {
  namespaced: true,

  state: {
    list: [],
  },

  actions: {
    // getAllContacts({ commit, dispatch, state }, payload) {
    //   return new Promise((resolve, reject) => {
    //     axios.get(payload.url)
    //       .then(async (response) => {
    //         state.list = response
    //         resolve(response.data)
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //         reject();
    //       });
    //   });
    // }
    
    async getContactById({}, id) {
      const contact = await axiosRequest.get(`../../contacts/${parseInt(id)}`)
      return contact[0]
    },
  },
};