export default {
  primaryPaths(key) {
    // const currentPath = window.location.pathname;
    // const pathSegments = currentPath.split('/');
    // pathSegments.shift();
    
    let paths = {
      'app': JSON.parse(app.dataset.page).props.app,
      'tenant': JSON.parse(app.dataset.page).props.tenant,
      'module': JSON.parse(app.dataset.page).props.module,
    };

    return paths[key];
  }
}