import axiosRequest from '@/functions/axiosRequests.js';

export default {
    namespaced: true,

    state: {
        tenant: '',
        list: [],
        groups: [],
        types: [],
        accounts: [],
    },

    actions: {
      // async getAll({ commit, dispatch, state }) {
      //   try  {
      //       let urlPaths = window.location.pathname.split('/').splice(0, 3);
      //       const { data } = await axios.get(`${urlPaths.join('/')}/resources/accounts`);

      //       if (data) {
      //           state.groups = data.accountGroups;
      //           state.taxRates = data.taxRates;
              
      //       }
      //   } catch (e) {
      //       console.log(e)
      //   }
      // },

      async getAll({ state }, params) {
        return await axiosRequest.get(params.url)
          .then((response) => {
            state.groups = response.accountGroups;
            state.taxRates = response.taxRates;
            state.list = response.accounts;
          });
      },

      async getResource({ commit, dispatch, state }, params) {
        return new Promise((resolve, reject) => {
          axios.get(params.url)
          .then(async (response) => {
            state.rates = response.data
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            reject();
          });
        });
      },

      /* Requires an array of TYPES from dispatch as it will be converted to query strings */
      async getResourceBy({ commit, dispatch, state }, params) {
        const typeParams = new URLSearchParams();
        
        params.types.forEach(obj => {
            Object.entries(obj).forEach(([key, value]) => {
                typeParams.append(`${key}[]`, value);
            });
        });

        return new Promise((resolve, reject) => {
          axios.get(`${params.url}${params.types.length ? `&${typeParams.toString()}` : ''}`)
          .then(async (response) => {
            state.rates = response.data
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            reject();
          });
        });
      },
    },
};
