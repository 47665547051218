// require('./bootstrap');
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/core/jquery-v3.3.1/jquery.min.js';
import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { createStore } from 'vuex'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { InertiaProgress } from '@inertiajs/progress'
import { translations } from './functions/translations.js';
import { formatter } from './functions/formatter.js';
import { processors } from './functions/processors.js';
import constants from '@/constants.js';
import eventBus from '@/eventBus.js';
import { dataTables } from './plugins/dataTables.js';
import 'animate.css';
import alerts from './store/Module/alerts'
import taxRates from './store/Module/taxRates'
import accounts from './store/Module/accounts'
import withholding from './store/Module/withholding'
import contacts from './store/Module/contacts'
import salesCredits from './store/Module/Sales/salesCredits'
import salesCollections from './store/Module/Sales/salesCollections'
import banking from './store/Module/CashBanking/banking'
import cash from './store/Module/CashBanking/cash'
import billsToPay from './store/Module/Purchases/billsToPay'
import advancePayments from './store/Module/Purchases/advancePayments'
import liquidation from './store/Module/Purchases/liquidation'
import billsPayments from './store/Module/Purchases/billsPayments'
import axios from "axios";
import Swal from 'sweetalert2'
import moment from 'moment';
import lodash from 'lodash'
import mitt from 'mitt'

window.Swal = Swal
window.axios = axios
window.moment = moment
window._ = lodash
// window.$ = window.jQuery = $;
window.jQuery = $;
window.$ = $;

InertiaProgress.init()

const emitter = mitt();

createInertiaApp({
  resolve: name => import(`./Pages/${name}.vue`),
  resolve: name => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  // resolve: name => require(`./Pages/${name}`),
  setup({ el, App, props, plugin }) {

    const app = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(store)
      
    //Functions, plugins and packages
    app.config.globalProperties.emitter = emitter
    app.config.globalProperties.$translations = translations;
    app.config.globalProperties.dataTables = dataTables;
    app.config.globalProperties.constants = constants;
    app.config.globalProperties.eventBus = eventBus;
    app.config.globalProperties.$moment = moment;
    app.config.globalProperties.formatter = formatter;
    app.config.globalProperties.translate = processors.translate;
    app.config.globalProperties.validate = processors.validate;

    app.mount(el)
  },
})

const store = createStore({
  modules: {
    alerts,
    salesCredits,
    salesCollections,
    accounts,
    taxRates,
    withholding,
    contacts,
    banking,
    cash,
    billsToPay,
    advancePayments,
    liquidation,
    billsPayments,
  }
})
