import axiosRequest from '@/functions/axiosRequests.js';

export default {
  namespaced: true,

  state: {
    list: [],
    withholdingRates: [],
  },

  actions: {
    async getAll({ state }, params) {
      return await axiosRequest.get(params.url)
        .then((response) => {
          state.list = response;
        });
    },

    async getResource({ commit, dispatch, state, rootState }, url) {
      return new Promise((resolve, reject) => {
        axios.get(url)
        .then(async (response) => {
          rootState.alerts.saving = false
          state.withholdingRates = response.data
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject();
        });
      });
    },

    async getWithholdingById({ commit, dispatch, state }, id) {
      const withholding = _.find(state.withholdingRates, function(withholding) { return withholding.id === id; })
      
      return await withholding
    },
  }
}